.tech-logos-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
}

.tech-logos-content {
    border: 1px solid var(--primary-color);
    border-radius: 16px;
    margin: 4px;
    
    transition: transform 0.2s ease-in-out;
}

.tech-logos-content:hover {
    transform: scale(1.10);
}

.tech-logos-content img {
    width: 50px;
    height: 50px;
    padding: 10px;
}

@media (min-width: 850px){
    .tech-logos-content img {
        width: 100px;
        height: 100px;
        padding: 20px;
    }
}
