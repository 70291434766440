.logo {
    position: relative;
    padding: 10px;
}

.logo img {
    height: 70px;
}

.logo-text {
    font-size: 24px;
    font-family: montserrat-regular;
    display: flex;
    position: absolute;
    opacity: 100%;
    padding-left: 80px;
    transition: opacity 1s, padding-left 1s;
}

.logo-text:hover {
    opacity: 0%;
    padding-left: 70px;
    transition: opacity 1s, padding-left 1s;
}

.logo-text div {
    margin-top: 32px;
}

.logo-text-blue {
    color: var(--secondary-color);
}

.logo-text-gray {
    color: var(--primary-color);
}

@media (max-width: 1200) {
    .logo {
        padding-left: 0px;
    }
}