.prompt-dropdown {
    background-color: var(--secondary-color);
    color: var(--background-color);
    margin-right: 8px;
    letter-spacing: 0.1em;
    font-family: montserrat-regular;
    height: 28px;
    margin-bottom: 4px;
    border-radius: 8px;
    margin-left: 16px;
}

.prompt-dropdown-option {
    background-color: var(--secondary-color);
}
