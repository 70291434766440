.prompts-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.display-prompts-container {
    display: flex;
    flex-direction: column;
}
