.footer-main {
    display: flex;
    justify-content: center;
    position: relative;
    width: auto;
    height: 50px;
    
    font-size: 14px;
    color: var(--primary-color);
    font-family: merriweather-regular;
    top: 128px;
    padding-bottom: 128px;
}

#footer-content {
    width: 80%;
    text-align: center;
}
