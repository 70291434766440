.contact-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact-form-input {
  width: 60%;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 8px;
  border: 1px solid var(--primary-color);
}

.contact-form-input::placeholder {
    color: var(--primary-color);
}

.contact-form-textarea {
    width: 60%;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 8px;
    border: 1px solid var(--primary-color);
}

.contact-form-textarea::placeholder {
    color: var(--primary-color);
}

.contact-form-button {
    background-color: var(--secondary-color);
    color: var(--background-color);
    height: 40px;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    width: 60%;
    margin: 10px;
}

.contact-form-button:hover {
    background-color: var(--primary-color);
}
