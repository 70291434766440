.floatingchatbox-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
 
    position: fixed;
    background-color: white;
    bottom: 20px;
    right: 10px;
    border-radius: 32px;
    min-height: 15%;
    min-width: 15%;
    max-width: 95%;
    box-shadow: 1px 1px 8px;
}

.floatingchatbox-chatbox-container {
    display: flex;
    justify-content: space-between;
}

.floatingchatbox-chatbox-history-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    padding-right: 0px;
    padding-top: 8px;
}

.floatingchatbox-button-container {
    display: flex;
    width: 100%;
    justify-content: center;
    padding-top: 8px;
}

.floatingchatbox-close-button {
    margin-bottom: 4px;
    background-color: var(--secondary-color);
    border-radius: 8px;
    border: none;
    color: white;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 4px;
    padding-bottom: 4px;
    letter-spacing: 0.1em;
    font-family: montserrat-regular;
}

.floatingchatbox-willai-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    position: relative;
    height: 10vh;
}

.floatingchatbox-willai-text-container {
    padding-left: 16px;
    padding-right: 16px;
    font-size: 18px;
}

.floatingchatbox-willai-image-container img {
    height: 100%;
}

.new-chat-button {
    margin-bottom: 4px;
    background-color: var(--secondary-color);
    border-radius: 8px;
    border: none;
    color: white;
    padding-top: 4px;
    padding-bottom: 4px;
    letter-spacing: 0.1em;
    font-family: montserrat-regular;
}
