.blog-title-container {
    display: flex;
    justify-content: center;
    width: 100%;
    font-size: 32px;
    font-weight: bold;
    padding: 16px;
}

.blog-date-container {
    display: flex;
    justify-content: center;
    margin-bottom: 32px;
}

.blog-image-container {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 32px;
}

.blog-body-container {
    width: 100%;
}

.blog-body {
    font-size: 16px;
    line-height: 1.5;
    padding: 32px;
    white-space: pre-wrap;
    overflow: hidden;
    font-family: montserrat-regular;
}
