@font-face {
    font-display: swap;
    font-family: opensans-bold;
    src: url("../fonts/OpenSans-Bold.ttf");
}

@font-face {
    font-display: swap;
    font-family: montserrat-regular;
    src: url("../fonts/Montserrat-Regular.ttf");
}

@font-face {
    font-display: swap;
    font-family: merriweather-regular;
    src: url("../fonts/Merriweather-Regular.ttf");
}

@font-face {
    font-display: swap;
    font-family: leaguespartan-regular;
    src: url("../fonts/LeagueSpartan-Regular.ttf");
}

