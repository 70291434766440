.fadeIn-inactive {
    opacity: 0;
    transform: scale(0.80);
    transition: opacity 300ms ease-out, transform 300ms ease-out, visability 300ms ease-out;
}

.fadeIn-active {
    opacity: 1;
    transform: scale(1.0);
    transition: opacity 300ms ease-out, transform 300ms ease-out, visability 300ms ease-out;
}
