#menu-main {
    color: var(--primary-color);
    display: flex;
    margin-top: -34px;
    z-index: 999;
    position: fixed;
    right: 0px;
    padding-right: 2%;
}

.menu-item {
    text-align: center;
    line-height: 40px;
    width: 100px;
    height: 50px;
    font-size: 18px;
    font-family: opensans-bold;
    cursor: pointer;
    background-color: var(--background-color);
    transition: background-color 2.0s;
    border-radius: 4px;
}

.menu-item:hover {
    background-color: var(--secondary-background-color);
    transition: background-color 0.6s;
}

.menu-item-active,
.menu-item-inactive {
    width: auto;
    height: 6px;
    border-radius: 4px;
}

.menu-item-inactive {
    background-color: var(--primary-background-color);
    transition: background-color 0.3s;
}

.menu-item-active {
    background-color: var(--secondary-color);
    transition: background-color 2s;
}

.menu-icon {
    width: 0px;
    height: 0px;
}

@media (max-width: 800px) {
    #menu-main {
        display: inline;
        background: var(--background-color);
        border-radius: 10px;
        padding: 10px;
        margin-right: 10px;
        min-width: 40px;
        min-height: 40px;
    }

    .menu-icon {
        background-color: var(--secondary-color);
        width: 30px;
        height: 4px;
        border-radius: 3px;
        margin: 4px;
        opacity: 100%;
        transition: all 0.2s;
    }

    #menu-main:hover .menu-icon {
        background-color: var(--background-color);
        width: 30px;
        height: 0px;
        border-radius: 0px;
        margin: 0px;
        opacity: 0%;
        transition: all 0.2s;
    }

    #menu-main:hover {
        box-shadow: 0px 0px 4px 1px gray;
    }

    #menu-main:hover .menu-item {
        height: 30px;
        line-height: 30px; 
        margin-right: 6px;
        width: 100px;
        font-size: 16px;
        opacity: 100%;
        transition: all 0.2s;
    }

    #menu-main:hover .menu-item-active {
        opacity: 100%;
        transition: all 2s;
    }

    .menu-item {
        height: 0px;
        line-height: 0px; 
        margin-right: 0px;
        width: 0px;
        font-size: 0px;
        opacity: 0%;
        transition: all 0.2s;
    }

    .menu-item-active,
    .menu-item-inactive {
        width: 6px;
        height: 30px;
        position: absolute;
        right: 6px;
        opacity: 0%;
    }
}

@media (min-width: 1200px) {
    #menu-main {
        right: 10%;
    }
}
