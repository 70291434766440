.content-main {
    border-radius: 8px;

    position: relative;
    width: 94%;
    margin-left: auto;
    margin-right: auto;
    min-height: 300px;
    height: auto;

    /*Adjustments*/
    top: 0px;
    padding-top: 110px;

    /*Font*/
    color: var(--primary-color);
    font-size: 20px;
    font-family: montserrat-regular;
}

.content-main h1 {
    width: 60%;
    margin-left: 0px;
    margin-right: auto;
    color: var(--secondary-font-color);
}

.content-main h2 {
    width: 60%;
    margin-left: 0px;
    margin-right: auto;
    font-size: 20px;
    font-family: merriweather-regular;
}

.content-main p {
    width: 60%;
    position: relative;
    margin-left: 0px;
    margin-right: auto;
    font-family: merriweather-regular;
    line-height: 160%;
}

#about-content,
#projects-content,
#skills-content,
#thoughts-content,
#contact-content {
    scroll-margin: 40px;
}

@media (min-width: 1200px) {
    .content-main {
        font-size: 22px;
    }
}

@media (max-width: 800px) {
    .content-main {
        font-size: 16px;
        min-height: 200px;
    }

    .content-main h2 {
        font-size: 18px;
    }
    
    .content-main h1 {
        font-size: 20px;
    }
}
