body {
    --primary-color: #474747;
    --secondary-color: #0051ff;
    --background-color: white;
    --secondary-background-color: #eeeeee;
    --secondary-font-color: #373737;
    background-color: var(--background-color);
    justify-content: center;
}

main {
    width: 100%;
    margin: 0 auto;
    margin-top: 60px;
}
