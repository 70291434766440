.project-card-container {
    display: flex;
    flex-wrap: wrap;

    padding-left: 3%;
    width: 98%;
    height: 65vh;
    color: var(--primary-color);
    border-radius: 2vh;
    transition: transform 0.6s ease-in-out;
    overflow: hidden;
    background: linear-gradient(to bottom, var(--secondary-color), white);
}

.project-card-container:hover {
    transform: scale(1.02);
}

.project-card-content-container {
    display: flex;
    justify-content: left;
    align-items: center;
    display-content: space-between;
    width: 100%;
    height: 70vh;
}

.project-card-image-container {
    height: 100%;
    width: 100%;
    
    position: relative;
    animation-duration: 8s;
    animation-name: cardSlideIn;
    left: 3%;
    opacity: 0%;
}

.project-card-image-container img{
    position: relative;
    height: 60%;
    border-radius: 16px;
    box-shadow: 0px 2px 8px var(--primary-color);
}

.project-card-text-container {
    display: flex;
    justify-content: left;
    align-items: top;

    height: 100%;
    margin-left: 5%;
    margin-right: 5%;
    opacity: 0.0;
    font-size: 26px;
    color: white;
    width: 100%;

    text-align: center;
 
    animation-duration: 8s;
    animation-name: cardFadeIn;
}

.project-card-container h1 {
    font-size: 32px;
    color: white;
}

@media (min-width: 850px){
    .project-card-container {
        height: 75vh;
    }

    .project-card-text-container {
        font-size: 42px;
    }

    .project-card-image-container img {
        height: 80%;
    }

    .project-card-container h1 {
        font-size: 42px;
    }
}

.store-badges-container {
    display: flex;
    justify-content: right;
    align-items: center;
    display-content: space-between;
    width: 95%;
}

.store-badges-container img {
    max-width: 20%;
    max-height: 60px;
}

@keyframes cardSlideIn {
    0% {opacity: 0.0; left: -100%}
    40% {opacity: 1.0; left: 3%}
    85% {opacity: 1.0; left: 3%}
    100% {opacity: 0.0; left: 150%}
}

@keyframes cardFadeIn {
    0% {opacity: 0.0;}
    40% {opacity: 1.0;}
    50% {opacity: 1.0;}
    100% {opacity: 0.0;}
}
