.thought {
    display: flex;
    flex-direction: column;
    flex: 2;
    border-radius: 20px;
    max-height: 260px;
    min-width: 30%;
    padding: 4%;
    margin: 2%;
    box-shadow: 0px 0px 8px lightgray;
    transition: transform 0.6s ease-in-out;
}

.thought:hover {
    transform: scale(1.02);
}

.thought-title {
    font-size: 20px;
}

.thought-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60%;
}

.thought-image-container img {
    max-width: 100%;
}

.thought-body {
    font-size: 14px;
    overflow: hidden;
}

@media (max-width: 800px) {
    .thought-title {
        font-size: 14px;
    }

    .thought-body {
        font-size: 12px;
        overflow: hidden;
    }
}
