.chat-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 60vw;
    height: auto;
}

.chat-conversation-area {
    position: relative;
    width: 90%;
    border-radius: 16px;
    border: 1px solid var(--primary-color);
    white-space: pre-wrap;
    background-color: var(--background-color);
}

.chat-conversation-area-padding {
    margin-top: 4px;
    margin-bottom: 4px;
    margin-right: 8px;
    margin-left: 8px;
    min-height: 20vh;
    max-height: 20vh;
    overflow: auto;
    font-size: 14px;
}

.chat-message-container {
    display: flex;
    justify-content: left;
    align-items: center;
    display-content: space-between;
    padding: 8px;
}

.chat-message-text-container {
    padding-left: 16px;
    overflow: auto;
}

.chat-input-area {
    height: 10%;
    width: 80%;
    margin: 8px;
    border: 1px solid var(--primary-color);
    border-radius: 8px;
    padding: 8px;
}

.chat-conversation-button {
    background-color: var(--secondary-color);
    border-radius: 8px;
    color: white;
    height: 24px;
    border: none;
    width: 60%;
    margin-bottom: 8px;
    letter-spacing: 0.1em;
    font-family: montserrat-regular;
}

.chat-conversation-button:hover {
    background-color: var(--primary-color);
}

.chat-code-container {
    border-radius: 16px;
}

@media (min-width: 500px) {
    .chat-conversation-area-padding {
        min-height: 50vh;
        max-height: 50vh;
    }
}
