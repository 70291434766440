.user-cell-edit-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.user-cell-textarea {
    padding: 0px;
}

.user-cell-edit-button {
    background-color: var(--secondary-color);
    color: var(--background-color);
    padding-left: 16px;
    padding-right: 16px;
    border-radius: 8px;
    border: none;
    cursor: pointer;
}

.user-cell-cancel-button {
    background-color: var(--secondary-color);
    color: var(--background-color);
    padding-left: 16px;
    padding-right: 16px;
    border-radius: 8px;
    border: none;
    cursor: pointer;
}

.user-cell-save-button {
    background-color: green;
    color: var(--background-color);
    padding-left: 16px;
    padding-right: 16px;
    border-radius: 8px;
    border: none;
    cursor: pointer;
}

.user-cell-delete-button {
    background-color: red;
    color: var(--background-color);
    padding-left: 16px;
    padding-right: 16px;
    border-radius: 8px;
    border: none;
    cursor: pointer;
}

.user-create-input {
    border: 1px solid var(--primary-color);
    border-radius: 4px;
    margin: 8px;
    padding: 4px;
}

.user-create-input::placeholder {
    color: var(--primary-coor);
}

.user-create-button {
    background-color: var(--secondary-color);
    color: var(--background-color);
    padding-left: 16px;
    padding-right: 16px;
    border-radius: 8px;
    border: none;
    cursor: pointer;
}
