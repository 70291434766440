.header-main {
    background-color: var(--background-color);
    width: 100%;
    display: flex;
    position: fixed;
    top: 0px;
    left: 0px;
    justify-content: center;
    z-index: 1;
}

#header-content {
    width: 100%;
    display: flex;
    position: relative;
    top: 0px;
}

@media (min-width: 1200px) {
    #header-content {
        width: 90%;
    }
}
