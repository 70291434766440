.chat-history-message-container {
    padding-top: 8px;
    padding-bottom: 8px;
    border: 1px solid var(--primary-color);
    margin: 4px;
    margin-bottom: 8px;
    padding: 4px;
    padding-bottom: 0px;
    border-radius: 8px;
}

.chat-history-message-container:hover {
    background-color: #e0e0e0;
}

.chat-history-message {
    padding-right: 16px;
    font-size: 14px;
}

.chat-history-message-delete-button {
    margin-bottom: 4px;
    background-color: var(--secondary-color);
    border-radius: 8px;
    border: none;
    color: white;
    padding-top: 2px;
    padding-bottom: 2px;
    font-size: 10px;
    letter-spacing: 0.1em;
    font-family: montserrat-regular;
}

.chat-history-message-delete-button:hover {
    background-color: var(--primary-color);
}
