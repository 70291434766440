.login-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px;
    margin: 8px;
}

.login-form input{
    margin: 8px;
    padding: 4px;
    border-radius: 4px;
    border: 1px solid var(--primary-color);
}

.login-form input::placeholder {
    color: var(--primary-color);
}

.login-form-button {
    background-color: white;
    color: var(--primary-color);
    border-radius: 4px;
    border: 1px solid var(--primary-color);
}

.login-form input[type="submit"] {
    color: var(--primary-color);
}
