.display-prompt-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.display-prompt-name-container {
    margin: 4px;
    font-weight: bold;
    font-color: var(--primary-color);
}

.display-prompt-text-container {
    margin: 4px;
}

.display-prompt-text-container textarea {
    width: 100%;
    height: 50px;
    border-radius: 8px;
    border-color: var(--primary-color);
    padding: 4px;
}

.prompt-buttons-container {
    display: flex;
}

.prompt-button {
    background-color: var(--secondary-color);
    color: var(--background-color);
    border-radius: 4px;
    border: none;
    cursor: pointer;
    margin: 4px;
    padding: 4px;
}
