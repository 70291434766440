.chatmanager-container {
    border: 1px solid var(--primary-color);
    border-radius: 16px;
    margin-bottom: 16px;
    padding: 8px;
}

.chatmanager-message-container {
    display: flex;
    justify-content: left;
    align-items: center;
    display-content: space-between;
    padding: 8px;
}

.chatmanager-message-text-container {
    padding-left: 4px;
    padding-right: 4px;
}

.chatmanager-delete-button-container {
    display: flex;
    justify-content: center;
}

.chatmanager-delete-button {
    background-color: var(--secondary-color);
    color: var(--background-color);
    padding: 8px;
    padding-left: 16px;
    padding-right: 16px;
    border-radius: 8px;
    border: none;
    cursor: pointer;
}
