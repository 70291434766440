.dashboardmenu-container {
    display: flex;
    justify-content: space-between;
}

.dashboardmenu-link {
    padding: 8px;
}

.dashboardmenu-link-style,
.dashboardmenu-link-style:visited,
.dashboardmenu-link-style:hover {
    text-decoration: none;
    color: var(--primary-color);
    font-size: 18px;
}

