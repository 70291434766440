.chat-history-container {
    display: flex;
    flex-direction: column;
    border: 1px solid black;
    border-radius: 16px;
    padding: 4px;
    padding-right: 0px;
    margin-top: 4px;
    margin-bottom: 4px;
    max-height: 20vh;
    overflow: hidden;
}

.chat-history-container-scroll {
    overflow: auto;
}

@media (min-width: 500px) {
    .chat-history-container {
        max-height: 50vh;
        max-width: 192px;
        min-width: 128px;
    }
}
