.modal-active {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    height: 100%;
    width: 100%;
    background: rgba(255, 255, 255, 0.8);
    top: 0px;
    left: 0px;
    z-index: 1000;
    text-align: center;
    animation-duration: 500ms;
    animation-name: modalFadeIn;

    font-size: 20px;
    color: var(--primary-color);
    font-family: montserrat-regular;
}


/*Image*/
.modal-image-container {
    display: flex;
    height: 90%;
    width: 100%;
    justify-content: center;
}

.modal-image-container img {
    height: 100%;
    border-radius: 20px;
}

/*Blog*/
.modal-blogpost-container {
    background-color: #fff;
    overflow: hidden;
    width: 80%;
    height: 80%;

    border-radius: 20px;
    min-width: 30%;
    padding: 4%;
    box-shadow: 1px 1px 8px;
    text-align: left;
}

.modal-blogpost-inner-container {
    overflow: auto;
    height: 100%;
    width: 100%;
}

.modal-blogpost-title {
    font-size: 28px;
    padding: 2%;
    padding-bottom: 0%;
}

.modal-blogpost-image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
}

.modal-blogpost-image-container img {
    width: 50%;
    height: auto;
}

.modal-blogpost-date-container {
    display: flex;
    justify-content: left;
    margin-bottom: 32px;
    font-size: 16px;
    padding-top: 2%;
}

.modal-blogpost-body-container {
    font-size: 18px;
    padding: 2%;
}

.modal-blogpost-body {
    line-height: 1.5;
    padding: 32px;
    white-space: pre-wrap;
    overflow: hidden;
    font-family: montserrat-regular;
}

@media (max-width: 800px) {
    .modal-blogpost-title {
        font-size: 20px;
    }

    .modal-blogpost-body {
        font-size: 14px;
    }
}

/*Alert*/
.modal-alert-container{
    background-color: #fff;
    overflow: hidden;

    width: 50%;
    height: 30%;

    border-radius: 20px;
    min-width: 30%;
    padding: 4%;
    box-shadow: 1px 1px 8px;
}

.modal-alert-title{
    font-size: 28px;
    padding-bottom: 16px;
    padding: 2%;
}

.modal-alert-body{
    font-size: 18px;
    padding: 2%;
}

@media (max-width: 800px) {
    .modal-alert-title {
        font-size: 20px;
    }

    .modal-alert-body {
        font-size: 14px;
    }
}

@keyframes modalFadeIn {
    from {opacity: 0.0;}
    to {opacity: 1.0;}
}
