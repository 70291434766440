.logout-button {
    background-color: var(--secondary-color);
    color: var(--background-color);
    border-radius: 8px;
    border: none;
    cursor: pointer;
    margin: 8px;
    padding: 8px;
    padding-left: 16px;
    padding-right: 16px;
}
