.prompts-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 4px;
}

.prompts-form-input-name {
    margin-bottom: 4px;
    border: 1px solid var(--primary-color);
    border-radius: 4px;
    padding: 4px;
    width: 24%;
}

.prompts-form-input-name::placeholder {
    color: var(--primary-color);
}

.create-prompt-button {
    background-color: var(--secondary-color);
    border-radius: 4px;
    cursor: pointer;
    margin: 4px;
    color: white;
    border: none;
    outline: none;
}

.prompts-form-input-textarea {
    height: 50px;
    border-radius: 8px;
    border-color: var(--primary-color);
    padding: 4px;
}

.prompts-form-input-textarea::placeholder {
    color: var(--primary-color);
}
