#caricature {
    height: 450px;
    position: absolute;
    right: -40px;
    top: 120px;
}

@media (max-width: 800px) {
    #caricature {
        height: 220px;
        right: -10px;
    }
}
